import { buildQueryString, calculateAge, convertTimestamp, extractDataObj, fetchContent, getCurrentTime, latLongToCoordinates, openNotificationWithIcon } from "@/utils";
import Index, { fetchData } from "..";
import { MAP_API_KEY, api } from "@/.config";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ref } from "vue";
import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
const timezone = require('dayjs/plugin/timezone');
interface Auth {
	file: BinaryData;
}

const userData: any = localStorage.getItem("user");
const toJson = JSON.parse(userData);

export const getUserList = async ({ ...rest }: any) => {
	let output: any = { valid: false };
	const { pageLimit, pageNo, sortBy, type, ...restOf } = rest
	if (Object.keys(restOf).length != 0) {
		rest.filter = true
	}
	await fetchContent("user/userList/" + buildQueryString(rest)).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getReportersList = async (params: any, { ...rest }: any) => {
	const start = (params.pageNo - 1) * params.pageLimit
	let output: any = { valid: false };
	await fetchContent(`feedback/report/${start}/${params.pageLimit}` + buildQueryString(rest)).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getFeedbackList = async (params: any, { ...rest }: any) => {
	const start = (params.pageNo - 1) * params.pageLimit
	let output: any = { valid: false };
	await fetchContent(`feedback/list/${start}/${params.pageLimit}` + buildQueryString(rest)).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getDeletedList = async ({ ...rest }: any) => {
	let output: any = { valid: false };
	await fetchContent(`user/userList/` + buildQueryString(rest)).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const uploadImage = async (id: string, fullName: string, file: any) => {
	return await Index.post<Auth>(`${api.base}user/profile-upload/${id}/public/${id}-${Date.now()}.jpg/${fullName}`, file)
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const deleteImage = async (id: string, url: any) => {
	await fetchData(`user/media-item/delete/${id}/${url}`, true, id)
		.then((res) => {
			res;
			if (res?.data?.fileDeleted) {
				openNotificationWithIcon("success", "File deleted success");
			}
		})
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};


export const getDashboardList = async () => {
	let output: any = { valid: false };
	await fetchContent(`user/dashboardList`).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getAstroSettingList = async () => {
	let output: any = { valid: false };
	await fetchContent(`setting/protocols/list/${toJson._id}`).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const astroUpdateApi = async (id: string, data: any) => {
	return await Index.put<Auth>(`${api.base}setting/protocol/edit/${id}`, data)
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const astroSynastryAddApi = async (data: any) => {
	return await Index.post<Auth>(`${api.base}setting/protocol/save`, data)
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const getListAppSetting = async () => {
	let output: any = { valid: false };
	await fetchContent(`setting/app-settings`).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const putAppSetting = async (data: any) => {
	return await Index.put<Auth>(`${api.base}setting/app-settings`, data)
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const getIpWhiteListApi = async (id: string) => {
	let output: any = { valid: false };
	await fetchData(`setting/ip-whitelist/list/${id}`).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const putIpWhiteListApi = async (id: string, data: any,) => {
	return await Index.put<Auth>(`${api.base}setting/ip-whitelist/save/${id}`, data)
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const deleteIpWhiteListApi = async (id: string, ip: any) => {
	let output: any = { valid: false };
	await fetchData(`setting/ip-whitelist/delete/${id}/${ip}`, true).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getIPV4Address = async (): Promise<string> => {
	try {
		const response = await fetch("https://api.ipify.org?format=json");
		const data = await response.json();
		return data.ip; // Return the IP address
	} catch (error) {
		console.error("Error fetching IP address:", error);
		throw error; // Rethrow the error for handling
	}
};

export const getIPV6Address = async (): Promise<string> => {
	try {
		const response = await fetch("https://api64.ipify.org?format=json");
		const data = await response.json();
		return data.ip; // Return the IPv6 address
	} catch (error) {
		console.error("Error fetching IPv6 address:", error);
		throw error; // Rethrow the error for handling
	}
};

export const getDevices = async (id: string) => {
	let output: any = { valid: false };
	await fetchData(`setting/device/versions/${id}`).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const putDevicesApi = async (data: any, id: string) => {
	return await Index.put<Auth>(`${api.base}setting/device/save-versions/${id}`, data)
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const deleteSynastry = async (itemId: string, userId: string) => {
	let output: any = { valid: false };

	await fetchData(`setting/protocol/delete/${itemId}/${userId}`, true).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const addPair = async (data: any) => {
	return await Index.post<Auth>(`${api.base}pair/create`, data)
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const genderByMonth = async (year: number) => {
	return await Index.post<Auth>(`${api.base}user/analytics/gender`, { year })
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const deletedAccountByMonth = async (year: number) => {
	return await Index.post<Auth>(`${api.base}user/analytics/deletedusers`, { year })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const membersByLocation = async (data: any, limit: Number) => {
	return await Index.post<Auth>(`${api.base}user/analytics/location`, { data, limit })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const addGroupApi = async (data: any) => {
	return await Index.post<Auth>(`${api.base}pair/create/group`, data)
		.then((res) => {
			openNotificationWithIcon("success", res?.message);
			return res
		})
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const getGroupListApi = async () => {
	let output: any = { valid: false };

	await fetchData(`pair/groupList`).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getMultiPairList = async (data: any) => {
	return await Index.post<Auth>(`${api.base}pair/multiPairList`, data)
		.then((res) => {
			return res
		})
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const deleteMultiPairAndGroupList = async (groupId: any) => {
	let output: any = { valid: false };

	await fetchData(`pair/pair-group/${groupId}`, true).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const addLanguages = async (data: any) => {
	return await Index.post<Auth>(`${api.base}languages/add-language`, data)
		.then((res) => res)
		.catch((error) => {
			throw error;
		});
};


export const getLanguagesList = async (params: any, { ...rest }: any) => {
	const start = (params.pageNo - 1) * params.pageLimit
	let output: any = { valid: false };
	await fetchContent(`languages/get-languages/${start}/${params.pageLimit}/` + buildQueryString(rest)).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const deleteLanguage = async (itemId: string) => {
	let output: any = { valid: false };

	await fetchData(`languages/delete-language/${itemId}`, true).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const updateLanguage = async (id: string, data: any) => {
	return await Index.put<Auth>(`${api.base}languages/update-language/${id}`, data)
		.then((res) => res)
		.catch((error) => {
			throw error;
		});
};

export const addContent = async (data: any) => {
	return await Index.post<Auth>(`${api.base}languages/add-language-category`, data)
		.then((res) => res)
		.catch((error) => {
			throw error;
		});
};

export const getCategoryApi = async (params: any, rest: any) => {
	const { sortBy, orderBy, category } = rest;
	const start = (params.pageNo - 1) * params.pageLimit;
	let output: any = { valid: false };

	const query = { ...rest, sortBy, orderBy, category };
	await fetchContent(`languages/list-category/${start}/${params.pageLimit}/` + buildQueryString(query)).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getLanguagesCategory = async (params: any, rest: any) => {
	const { sortBy, orderBy, selectedLanguageId } = rest;
	const start = Number(params.pageNo - 1) * Number(params.pageLimit);
	let output: any = { valid: false };

	const query = { ...rest, sortBy, orderBy, languageId: selectedLanguageId };
	await fetchContent(`languages/list-language-category/${start}/${params.pageLimit}/` + buildQueryString(query)).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const updateContent = async (id: string, data: any) => {
	return await Index.put<Auth>(`${api.base}languages/update-language-category/${id}`, data)
		.then((res) => res)
		.catch((error) => {
			throw error;
		});
};

export const addLocationForNotification = async (data: any) => {
	return await Index.post<Auth>(`${api.base}user/locations`, data)
		.then((res) => {
			return res
		})
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const sendManualNotification = async (data: any) => {
	return await Index.post<Auth>(`${api.base}user/send-notification`, data)
		.then((res) => {
			return res
		})
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};



export const getNotificationKey = async () => {
	let output: any = { valid: false };
	await fetchData(`user/notificationKey`).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getNotificationListApi = async (params: any, { ...rest }: any) => {
	const start = (params.pageNo - 1) * params.pageLimit
	let output: any = { valid: false };
	await fetchContent(`user/notification-list/${start}/${params.pageLimit}/` + buildQueryString(rest)).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const updateTranslate = async (id: string, languageId: any, data: any) => {
	return await Index.put<Auth>(`${api.base}languages/update-translate/${id}/${languageId}`, data)
		.then((res) => res)
		.catch((error) => {
			throw error;
		});
};

export const updateContentPublish = async (id: string, languageId: any, data: any) => {
	return await Index.put<Auth>(`${api.base}languages/update-Content-publish/${id}/${languageId}`, data)
		.then((res) => res)
		.catch((error) => {
			throw error;
		});
};

export const getTranslate = async (languageId: any) => {
	let output: any = { valid: false };

	await fetchData(`languages/get-translate?languageId=${languageId}`)
		.then((res: any) => {
			const result = extractDataObj(res);
			if (result instanceof Object && result.valid) {
				output = result;
			} else {
				openNotificationWithIcon("error", result?.message);
			}
		})
		.catch((error) => {
			openNotificationWithIcon("error", error);
		});

	return output;
};


export const getKeyListApi = async () => {
	let output: any = { valid: false };

	await fetchData(`languages/key-list`)
		.then((res: any) => {
			const result = extractDataObj(res);
			if (result instanceof Object && result.valid) {
				output = result;
			} else {
				openNotificationWithIcon("error", result?.message);
			}
		}).catch((error) => {
			openNotificationWithIcon("error", error);
		});
	return output;

}

export const getCategoryListApi = async (category: string) => {
	let output: any = { valid: false };
	await fetchData(`languages/category-key-list?category=${category}`)
		.then((res: any) => {
			const result = extractDataObj(res);
			if (result instanceof Object && result.valid) {
				output = result;
			} else {
				openNotificationWithIcon("error", result?.message);
			}
		}).catch((error) => {
			openNotificationWithIcon("error", error);
		});
	return output;
}

export const deleteCategoryApi = async (itemId: string) => {
	let output: any = { valid: false };

	await fetchData(`languages/delete-category/${itemId}`, true).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

export const getDeletedContentListApi = async (params: any, { ...rest }: any) => {
	const start = (params.pageNo - 1) * params.pageLimit
	let output: any = { valid: false };

	await fetchContent(`languages/deleted-Category-list/${start}/${params.pageLimit}/` + buildQueryString(rest))
		.then((res: any) => {
			const result = extractDataObj(res);
			if (result instanceof Object && result.valid) {
				output = result;
			} else {
				openNotificationWithIcon("error", result?.message);
			}
		}).catch((error) => {
			openNotificationWithIcon("error", error);
		});
	return output;
}

export const getDartFileVersionApi = async () => {
	let output: any = { valid: false };

	await fetchData(`languages/dart-version`)
		.then((res: any) => {
			const result = extractDataObj(res);
			if (result instanceof Object && result.valid) {
				output = result;
			} else {
				openNotificationWithIcon("error", result?.message);
			}
		}).catch((error) => {
			openNotificationWithIcon("error", error);
		});
	return output;

}

export const updateDartVersionApi = async (data: any) => {
	return await Index.put<Auth>(`${api.base}languages/update-dart-version`, data)
		.then((res) => res)
		.catch((error) => {
			throw error;
		});
}

export const getIncompleteProfileList = async (data: any) => {
	return await Index.post<any>(`${api.base}user/incomplete-profile-list`, data)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

export const getReportedListData = async (id: any, params: any) => {
	const start = (params.pageNo - 1) * params.pageLimit;
	let output: any = { valid: false };
	await fetchContent(`feedback/reporters-list/${id}/${start}/${params.pageLimit}`).then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;

}

export const getProfilesList = async (data: any) => {
	return await Index.post<any>(`${api.base}user/profiles-list`, data)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});

}

export const updateProfileRating = async (id: string, data: any,) => {
	return await Index.put<Auth>(`${api.base}user/update-profile/${id}`, data)
		.then((res) => res)
		.catch((error) => {
			console.log(error);
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};

const coreFields = [
	"mode",
	"roles",
	"gender",
	"preview",
	"dob",
	"pob",
	"deleted",
	"login",
	"_id",
	"fullName",
	"identifier",
	"status",
	"active",
	"createdAt",
	"modifiedAt",
	"placenames",
	"profiles",
	"preferences",
	"geo",
	"nickName",
	"reported",
	"blocked",
	"swipeTo",
	"swipeFrom",
	"recentSwipesFrom",
	"hasBlocked",
	"celebritySwipes",
	"hasReported",
	"answeredQuiz",
	"passes",
	"likes"
]

export const mapData = async (userList: any, userStatusFilter: any) => {

	const users: any[] = [];
	for (let j = 0; j < userList.length; j++) {
		const user = userList[j];
		if (user?.preferences && user?.preferences) {
			for (let i = 0; i < user?.preferences.length; i++) {
				const element = user?.preferences[i];
				if (!coreFields.includes(element.key)) {
					user[element?.key] = element?.value;
				}
			}
		}
		let place = ''
		let isAdm = false
		let pplaData = ''
		for (let i = user?.placenames.length - 1; i >= 0; i--) {
			const placeObj = user?.placenames[i];
			if (placeObj?.name) {
				if (placeObj?.type == "PCLI" || placeObj?.type == "ADM1" || placeObj?.type == "ADM2" || placeObj?.type == "ADM3" || placeObj?.type == "ADM4") {
					if (placeObj?.type == "ADM1" || placeObj?.type == "ADM2" || placeObj?.type == "ADM3" || placeObj?.type == "ADM4") {
						isAdm = true
					}
					place += place ? `, ${placeObj?.name}` : placeObj?.name
				}
				if (placeObj?.type == "PPLA") {
					pplaData = placeObj?.name
				}
			}
		}
		if (!isAdm && pplaData) {
			place = place ? `${pplaData}, ${place}` : pplaData
		}

		const loginDate = new Date();
		const currentDate = new Date();
		const oneMonthAgo = new Date();
		oneMonthAgo.setDate(currentDate.getDate() - 30)

		const userArray = [
			{ key: 'adminBlock', value: 'Admin Block' },
			{ key: 'inCompleted', value: 'Incomplete' },
			{ key: 'dormate', value: 'Dormate' },
			{ key: 'active', value: 'Active' },
		]

		let userStatus = ""

		if (userStatusFilter.user) {
			const strToArr = userStatusFilter.user.split(",");
			const userValueArray: any = []
			strToArr.map((ele: any) => {
				const findValue: any = userArray.find((e) => e.key == ele)
				userValueArray.push(findValue.value)
			})
			userStatus = userValueArray.join(",")
		}

		else
			if (user?.roles.includes('blocked')) {
				userStatus = "Admin Block"
			} else if (user.preferences.length == 0 || user.placenames.length == 0 || user.gender == " - " || user.pob == "" || user.dob == null || user?.profiles.length == 0 || user?.profiles[0]?.mediaItems.length <= 0) {
				userStatus = "Incomplete"
			} else if (user?.login && (loginDate < oneMonthAgo || loginDate > currentDate)) {
				userStatus = "Dormate"
			} else {
				userStatus = "Active"
			}
		console.log(user?.profiles[0]?.text, 752);

		const userMap = {
			_id: user._id,
			key: j,
			nickName: user?.nickName ? capitalizeFirstLetter(user?.nickName) : "-",
			// nickName: user?.nickName || "-",
			gender: user?.gender == "m" ? "Male" : user.gender == "f" ? "Female" : "-",
			age: user?.dob && calculateAge(user?.dob),
			residence: user?.residence,
			reported: user?.reported,
			blocked: user?.blocked || 0,
			answeredQuiz: user?.answeredQuiz ? 'Yes' : 'No',
			cordinates: user?.geo && user?.geo?.lat && user?.geo?.lng ? latLongToCoordinates(user?.geo?.lat, user?.geo?.lng) : "-",
			// modifiedAt: convertTimestamp(user?.modifiedAt),
			modifiedAt: dayjs.utc(user?.modifiedAt).format('DD-MMM-YYYY HH:mm'),
			createdAt: dayjs.utc(user?.createdAt).format('DD-MMM-YYYY HH:mm'),
			identifier: user?.identifier,
			dob: convertTimestamp(user?.dob, true),
			// login: convertTimestamp(user?.login),
			login: dayjs.utc(user?.login).format('DD-MMM-YYYY HH:mm'),
			profession: user?.profession,
			active: `${user?.active}`,
			userStatus: userStatus,
			accountStatus: user?.active ? 'Yes' : 'No',
			place,
			wantsToMeet: user?.genders ? user?.genders.includes('m') && user.genders.includes('f') ? "Both" : user?.genders.includes('m') ? "Male" : user.genders.includes('f') ? "Female" : "Other" : '-',
			relationshipStatus: user?.relationship_status,
			tob: user?.dob && getCurrentTime(user?.dob),
			pob: user?.pob,
			ageRange: user?.age_range && `${user?.age_range?.[0]}-${user?.age_range?.[1]}`,
			distanceRange: user?.max_distance,
			tropical_sidereal: user?.birth_chart_sign == "s" ? "Sidereal" : "Tropical",
			jungianType: user?.jungian_type,
			birthChartStyle: user?.birth_chart_type == "ei" ? "East Indian" : user?.birth_chart_type == "si" ? "South Indian" : user?.birth_chart_type == "ni" ? "North Indian" : user?.birth_chart_type == "w" ? "Western" : "-",
			liked: user?.popularity?.likeCount,
			starred: user?.popularity?.superLikeCount,
			passes: user?.popularity?.passesCount,
			popularityReported: user?.popularity?.reportedCount,
			popularityBlocked: user?.popularity?.blockedCount,
			notificationLikes: user?.push_notifications?.includes("been_liked") ? "Yes" : "No",
			notificationSuperLikes: user?.push_notifications?.includes("been_superliked") ? "Yes" : "No",
			notificationMessage: user?.push_notifications?.includes("message_received") ? "Yes" : "No",
			notificationMatches: user?.push_notifications?.includes("been_matched") ? "Yes" : "No",
			device: user?.device,
			appVersion: user?.app_version,
			build: user?.build,
			luckyTimeCount: user?.luckyTimeCount,
			matchMeupTimeCount: user.matchMeupTimeCount,
			userLikes: user?.userLikes,
			userSuperLikes: user?.userSuperLikes,
			userSwipes: user?.userSwipes,
			astroCompVisitCount: user?.astroCompVisitCount,
			jugnianCompVisitCount: user?.jugnianCompVisitCount,
			geoBirth: user?.char?.geo && user?.chart?.geo?.lat && user?.chart?.geo?.lng ? latLongToCoordinates(user?.chart?.geo?.lat, user?.chart?.geo?.lng) : "-",
			location: user?.location && user.location?.combine ? user.location?.combine : "-",
			matchesMade: user?.matchesMade,
			bio: user?.profiles?.[0]?.text,
		};

		users.push(userMap);
	}
	return users;
};

function capitalizeFirstLetter(str: string) {
	let words = str.toLowerCase().split(' ');
	let capitalizedWords = words.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1));
	return capitalizedWords.join(' ');
}

export const getUser = async (id: string) => {
	let output: any = { valid: false };

	await fetchContent("user/item/" + id + "/chart").then((res: any) => {
		const result = extractDataObj(res);
		if (result instanceof Object) {
			output = result;
			output.valid = true;
		}
	});
	return output;
};

const dateFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const getUserMap = async (user: any) => {
	if (user?.preferences && user?.preferences) {
		for (let i = 0; i < user?.preferences.length; i++) {
			const element = user?.preferences[i];
			if (!coreFields.includes(element.key)) {
				user[element?.key] = element?.value;
			}
		}
	}
	let place = ''
	let coordinates = ''
	for (let i = user?.placenames.length - 1; i >= 0; i--) {
		const placeObj = user?.placenames[i];
		if (placeObj?.name && (i == user?.placenames.length - 1 || i == user?.placenames.length - 2 || i == 0)) {
			place += place ? `, ${placeObj?.name}` : placeObj?.name
		}
		if (i == 0) {
			coordinates = `${placeObj?.geo?.lat} ${placeObj?.geo?.lng}`
		}
	}
	user.profession_public_element = user?.profession_public ? "public" : "private";
	user.residence_public_element = user?.residence_public ? "public" : "private";
	user.orientation_public_element = user?.orientation_public ? "public" : "private";
	user.relationship_public_element = user?.relationship_public ? "public" : "private";
	user.tob = user?.dob && dayjs.utc(user?.dob);
	user.selectDob = user?.dob && dayjs.utc(user?.dob, dateFormat);
	user.geoCordinate = user?.geo && user?.geo?.lat.toFixed(4) + "," + user?.geo?.lng.toFixed(4);
	user.place = place
	user.placeCoordinate = coordinates
	user.adminBlocked = user?.roles.includes("blocked") ? true : false;
	user.credit = user?.profiles?.[0]?.mediaItems?.[0]?.title
	user.liked = user?.popularity?.likeCount
	user.starred = user?.popularity?.superLikeCount
	user.reportedCount = user?.popularity?.reportedCount
	user.blocked = user?.popularity?.blockedCount
	user.image = user?.profiles?.[0]?.mediaItems.length > 0 ? 'image' : ''
	user.publicProfileText = user?.profiles?.[0]?.text
	user.roles = user?.roles.join(',')
	user.genders = user?.genders ? user.genders.length == 1 ? user?.genders?.[0] : 'b' : ''
	user.height = Number((Number(user?.height) * 100).toFixed(0))
	user.heightInFeet = user.height ? cmToFeetAndInches(user.height) : "0 ft. 0 in."
	user.matchMeupTimeCount = user?.matchMeupTimeCount
	user.luckyTimeCount = user?.luckyTimeCount
	user.userLikes = user?.userLikes
	user.userSuperLikes = user?.userSuperLikes
	user.userSwipes = user?.userSwipes
	user.astroCompVisitCount = user?.astroCompVisitCount
	user.jugnianCompVisitCount = user?.jugnianCompVisitCount
	user.geoBirth = user?.chart?.geo && user?.chart?.geo?.lat.toFixed(4) + " " + user?.chart?.geo?.lng.toFixed(4);
	user.location = user?.location && user.location?.combine ? user.location?.combine : "-"
	user.matchesMade = user?.matchesMade
	return user;
};

function cmToFeetAndInches(heightInCm: number) {
	const totalInches = heightInCm / 2.54;
	const feet = Math.floor(totalInches / 12);
	const inches = Math.round(totalInches % 12);
	return `${feet} ft. ${inches} in.`;
}

export const userSample = {
	mode: "",
	roles: [],
	gender: "",
	preview: "",
	dob: "",
	pob: "",
	login: "",
	_id: "",
	active: true,
	fullName: "",
	identifier: "",
	status: [],
	createdAt: "",
	modifiedAt: "",
	placenames: [],
	profiles: [
		{
			type: "",
			text: "",
			mediaItems: [
				{
					variants: [],
					_id: "",
					filename: "",
					mime: "",
					type: "",
					source: "",
					size: 0,
					title: "",
					attributes: {
						_id: "",
						height: 0,
						width: 0,
					},
				},
			],
			createdAt: "",
			modifiedAt: "",
			_id: "",
		},
	],
	preferences: [
		{
			type: "",
			_id: "",
			key: "",
			value: [],
		},
	],
	geo: {
		alt: 10,
		_id: "",
		lat: 0,
		lng: 0,
	},
	nickName: "",
	age_range: [],
	max_distance: 0,
	distance_type: "",
	show_online_status: true,
	hide_from_explore: false,
	push_notifications: [],
	genders: ["f"],
	orientation: "",
	purpose: "",
	orientation_public: false,
	residence: "",
	residence_public: false,
	profession_public: true,
	relationship_public: true,
	birth_chart_symbol: "",
	birth_chart_sign: "",
	birth_chart_type: "",
	relationship_status: "",
	profession: "",
	public: "",
	tob: "",
	geoCordinate: "",
};

export const adminMapData = (userList: any) => {
	const users: any[] = [];
	for (let j = 0; j < userList.length; j++) {
		const user = userList[j];
		if (user?.preferences && user?.preferences) {
			for (let i = 0; i < user?.preferences.length; i++) {
				const element = user?.preferences[i];
				user[element?.key] = element?.value;
			}
		}
		const userMap = {
			_id: user?._id,
			fullName: user?.fullName,
			identifier: user?.identifier,
			profession: user?.profession,
			role: user?.roles.filter((role: any) => role !== 'active').join(' , '),
			login: user?.login && dayjs.utc(user?.login).format('DD-MMM-YYYY HH:mm'),
			createdAt: user?.createdAt && dayjs.utc(user?.createdAt).format('DD-MMM-YYYY HH:mm'),
			roles: user?.roles
		}
		users.push(userMap);
	}
	return users
}

export const getCelebritiesMap = (userList: any) => {
	const users: any[] = [];
	for (let j = 0; j < userList.length; j++) {
		const user = userList[j];
		if (user?.preferences && user?.preferences) {
			for (let i = 0; i < user?.preferences.length; i++) {
				const element = user?.preferences[i];
				user[element?.key] = element?.value;
			}
		}

		const userMap = {
			_id: user?._id,
			liked: user?.likes,
			passes: user?.passes,
			dob: user?.dob ? formatTimestamp(user?.dob) : '-',
			pob: user.pob ? user.pob : '-',
			rodden: user?.rodden,
			fullName: user?.fullName,
			profession: user?.profession,
			// region: user?.georegion,
			celebrityRegion: user?.celebrityRegion,
			jungianType: user?.jungian_type,
			hasIntro: user?.profiles[0]?.text ? 'Yes' : '',
			height: user?.height && `${Number((Number(user?.height) * 100).toFixed(2))} cm`,
			createdAt: user?.createdAt && dayjs.utc(user?.createdAt).format('DD-MMM-YYYY HH:mm'),
			roles: user?.roles
		}
		users.push(userMap);
	}
	return users
}

export const reportedMapData = (userList: any) => {
	const users: any[] = [];
	for (let j = 0; j < userList.length; j++) {
		const user = userList[j];
		if (user?.preferences && user?.preferences) {
			for (let i = 0; i < user?.preferences.length; i++) {
				const element = user?.preferences[i];
				user[element?.key] = element?.value;
			}
		}

		const userMap = {
			key: j,
			_id: user?._id,
			fullName: user?.fullName,
			nickName: user?.nickName || user?.fullName,
			identifier: user?.identifier,
			login: user?.login && dayjs.utc(user?.login).format('DD-MMM-YYYY HH:mm') || '-',
			reports: user?.reports,
			joined: user?.createdAt,
			modifiedAt: user?.modifiedAt,
			userLikes: user?.userLikes,
			userSuperLikes: user?.userSuperLikes,
			userSwipes: user?.userSwipes,
			matchesMade: user?.matchesMade,
			likeCount: user?.likeCount,
			superLikeCount: user?.superLikeCount,
			totalCount: user?.totalCount,
			uniqueCount: user?.uniqueCount,

		}
		users.push(userMap);
	}
	return users
}

export const reportedNestedMapData = (userList: any) => {
	const users = userList?.map((e: any, i: any) => {
		return {
			key: i,
			_id: e?._id,
			nickName: e?.nickName,
			identifier: e?.identifier,
			createdAt: e?.createdAt && convertTimestamp(e?.createdAt),
			reason: e?.reason,
			text: e?.text,
		}
	})

	return users
}

export const feedbackMapData = (userList: any) => {

	const users = userList.map((e: any, i: any) => {
		return {
			key: i,
			_id: e?._id,
			fullName: e?.fullName,
			nickName: e?.nickName,
			email: e?.email,
			modifiedAt: e?.modifiedAt && dayjs.utc(e?.modifiedAt).format('DD-MMM-YYYY HH:mm'),
			createdAt: e?.createdAt && dayjs.utc(e?.createdAt).format('DD-MMM-YYYY HH:mm'),
			reason: e?.reason == "Comment" ? `User Report` : `Bug Report`,
			text: e?.text,
			attachments: e?.key == "bug_report" && e?.mediaItems.length > 0 ? "bug_report" : "-",
			mediaItems: e?.mediaItems
		}
	})
	return users
}

export const languageMapData = (userList: any) => {
	const users = userList.map((e: any, i: any) => {
		return {
			_id: e?._id,
			language: e?.language,
			key: e?.key,
			country: e?.country,
			isoCode: e?.isoCode,
			createdAt: e?.createdAt && convertTimestamp(e?.createdAt),
		}
	})
	return users

}

export const deletedMapData = (userList: any) => {
	const users = userList.map((e: any, i: any) => {
		let place = ''
		for (let i = e?.placenames.length - 1; i >= 0; i--) {
			const placeObj = e?.placenames[i];
			if (placeObj?.name && (i == e?.placenames.length - 1 || i == e?.placenames.length - 2 || i == 0)) {
				place += place ? `, ${placeObj?.name}` : placeObj?.name
			}

		}
		return {
			key: i,
			id: e?._id,
			nickName: e?.nickName,
			identifier: e?.identifier,
			modifiedAt: e?.modifiedAt && dayjs.utc(e?.modifiedAt).format('DD-MMM-YYYY HH:mm'),
			login: e?.login && dayjs.utc(e?.login).format('DD-MMM-YYYY HH:mm'),
			createdAt: e?.createdAt && dayjs.utc(e?.createdAt).format('DD-MMM-YYYY HH:mm'),
			blocked: e?.roles.includes('blocked') ? 'Yes' : 'No',
			gender: e?.gender == "m" ? "Male" : e.gender == "f" ? "Female" : "Other",
			place,
		}
	})

	return users
}

export const filteredUser = async (columns: any, userFilter: any) => {
	columns.forEach(async (column: any) => {
		let found = false;
		for (const i in userFilter.userList) {
			const element = userFilter.userList[i];
			if (i == column.key || element == column.key || userFilter.filtered.includes(column.key)) {
				found = true;
			}
		}
		column.filtered = found;
	});
}

export const synastry = async (synastry: any, synastryTableHeading: any) => {
	const formattedObject: any = {};

	synastry.forEach((item: any) => {
		const { label, ...values } = item;
		const matchingOrbSetting: any = synastryTableHeading.find((orbSetting: any) => orbSetting.value == label);
		const formattedValues: any = {};
		Object.keys(values).forEach((key) => {

			if (matchingOrbSetting.key == 'synastry' || matchingOrbSetting.key == 'gunaMilan') {
				formattedValues['val2'] = values.val1 && values.val1 * 100
			}

			formattedValues[key] = values[key] ? Number(values[key]) : '';

		});
		formattedObject[matchingOrbSetting.key] = formattedValues;
	});

	return formattedObject
}


export const getSynastry = async (synastry: any, synastryTableHeading: any) => {
	const synastryArray: any = []

	synastryTableHeading.forEach((rule: any) => {
		synastryArray.push({
			label: rule.value,
			value: rule.key
		});
	});
	for (const i in synastry) {
		const element = synastry[i]

		const matchingOrbSetting: any = synastryArray.find((orbSetting: any) => orbSetting.value == i);
		if (matchingOrbSetting) {
			Object.assign(matchingOrbSetting, element);
		}
		else {
			synastryArray.push({
				...element,
			});
		}
	}

	return synastryArray
}

export const checkBoxPlanetsArray: any = ref([
	{ value: "su", icon: "RuleRow/circle5.png", planet: "Su", fullName: "Sun" },
	{ value: "mo", icon: "RuleRow/path2.png", planet: "Mo", fullName: "Moon" },
	{ value: "me", icon: "RuleRow/path12.png", planet: "Me", fullName: "Mercury" },
	{ value: "ve", icon: "RuleRow/path3.png", planet: "Ve", fullName: "Venus" },
	{ value: "ma", icon: "RuleRow/path6.png", planet: "Ma", fullName: "Mars" },
	{ value: "ju", icon: "RuleRow/path4.png", planet: "Ju", fullName: "Jupiter" },
	{ value: "sa", icon: "RuleRow/path5.png", planet: "Sa", fullName: "Saturn" },
	{ value: "ra", icon: "RuleRow/path7.png", planet: "Ra", fullName: "Rahu" },
	{ value: "ke", icon: "RuleRow/path8.png", planet: "Ke", fullName: "Ketu" },
	{ value: "ur", icon: "RuleRow/path9.png", planet: "Ur", fullName: "Uranus" },
	{ value: "ne", icon: "RuleRow/path10.png", planet: "Ne", fullName: "Neptune" },
	{ value: "pl", icon: "RuleRow/path11.png", planet: "Pl", fullName: "Pluto" },
	{ value: "as", icon: "RuleRow/ASC.png", planet: "AS", fullName: "Ascendant" },
	{ value: "mc", icon: "RuleRow/MC.png", planet: "MC", fullName: "Midheaven" },
])

export const orbSettingCheckbox: any = ref([
	{ value: "conjunction", icon: "RuleColum/aspect-conjunct.png", cel: "0°", name: "Conjunction" },
	{ value: "opposition", icon: "RuleColum/opposite.png", cel: "180°", name: "Opposition" },
	{ value: "trine", icon: "RuleColum/trine.png", cel: "120°", name: "Trine" },
	{ value: "square", icon: "RuleColum/square.png", cel: "90°", name: "Square" },
	{ value: "quintile", icon: "RuleColum/path20.png", cel: "72°", name: "Quintile" },
	{ value: "sextile", icon: "RuleColum/aspect-sextile.png", cel: "60°", name: "Sextile" },
	{ value: "septile", icon: "RuleColum/path35.png", cel: "51°26'", name: "Septile" },
	{ value: "semi-square", icon: "RuleColum/aspect-semi-square-octile.png", cel: "45°", name: "Semi-square (Octile)" },
	{ value: "sesqui-square", icon: "RuleColum/rect2.png", cel: "135°", name: "Sesqui-square (Tri-Octile)" },
	{ value: "semi-sextile", icon: "RuleColum/aspect-semi-sextile.png", cel: "30°", name: "Semi-sextile" },
	{ value: "quincunx", icon: "RuleColum/aspect-quinquix.png", cel: "150°", name: "Quincunx" },
]);

export const synastryTableHeading = [
	{ key: "veryHigh", value: "Very high" },
	{ key: "high", value: "High" },
	{ key: "medium", value: "Medium" },
	{ key: "low", value: "Low" },
	{ key: "veryLow", value: "Very Low" },
];

export const heartLogoHeading: any = [
	{ key: "veryHigh", value: "Very high" },
	{ key: "high", value: "High" },
	{ key: "medium", value: "Medium" },
	{ key: "veryLow", value: "Very Low" },
];

export const synastryVsGmHeading = [
	{ key: "synastry", value: "Synastry" },
	{ key: "gunaMilan", value: "Guna Milan" },
];

export const rulesCount = [
	{ key: "communication", value: "Communication" },
	{ key: "connection", value: "Connection" },
	{ key: "energy", value: "Energy" },
	{ key: "stability", value: "Stability" },
	{ key: "totalSynastry", value: "Total Synastry" },
	{ key: "gunaMilan", value: "Guna Milan" },
	{ key: "totalScore", value: "Total Score" },
];

export const jsonToExcel = (jsonData: any) => {
	try {

		const planetsData: any = []
		checkBoxPlanetsArray.value.map((planet: any) => {
			const matchingOrbSetting: any = jsonData.planets.find((data: any) => data == planet.value);
			planetsData.push({ Planet: planet.fullName, Exist: matchingOrbSetting ? true : false })
		});

		const orbsData: any = []
		orbSettingCheckbox.value.map((orbs: any) => {
			const matchingOrbSetting: any = jsonData.orbs.find((data: any) => data.key == orbs.value);
			orbsData.push({ Orbs: orbs.name, Value: matchingOrbSetting && matchingOrbSetting.value, Exist: matchingOrbSetting ? true : false })
		});


		const categoryData: any = []
		for (const i in jsonData.synastryCategory) {
			const element = jsonData.synastryCategory[i];
			categoryData.push({ 'Category': i, val1: element.val1, val2: element.val2, val3: element.val3, val4: element.val4 })
		}

		const heartLogoData: any = []
		for (const i in jsonData.heartLogo) {
			const element = jsonData.heartLogo[i];
			heartLogoData.push({ 'Heart_Logo': i, val1: element.val1, val2: element.val2 })
		}

		const synastryVsGMData: any = []
		for (const i in jsonData.synastryVsGm) {
			const element = jsonData.synastryVsGm[i];
			synastryVsGMData.push({ 'Synastry_vs_GM': i, val1: element.val1, val2: element.val2 })
		}

		const synastryRulesData: any = []
		for (const i in jsonData.synastryRules) {
			const element = jsonData.synastryRules[i];
			synastryRulesData.push({ Rules: element.rules, Communication: element.communication, Connection: element.connection, Energy: element.energy, Stability: element.stability })
		}

		const categoryWs = XLSX.utils.json_to_sheet(categoryData);
		const orbsWs = XLSX.utils.json_to_sheet(orbsData);
		const planetsWs = XLSX.utils.json_to_sheet(planetsData);
		const heartLogoWs = XLSX.utils.json_to_sheet(heartLogoData);
		const synastryVsGMWs = XLSX.utils.json_to_sheet(synastryVsGMData);
		const synastryRulesWs = XLSX.utils.json_to_sheet(synastryRulesData);

		const wb = XLSX.utils.book_new();

		XLSX.utils.book_append_sheet(wb, planetsWs, 'Planets');
		XLSX.utils.book_append_sheet(wb, orbsWs, 'Orbs')
		XLSX.utils.book_append_sheet(wb, categoryWs, 'Synastry Category')
		XLSX.utils.book_append_sheet(wb, heartLogoWs, 'Heart Logo')
		XLSX.utils.book_append_sheet(wb, synastryVsGMWs, 'Synastry vs. GM')
		XLSX.utils.book_append_sheet(wb, synastryRulesWs, 'Synastry Rules')

		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const fileName = 'OutputData.xlsx';

		saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), fileName);

	} catch (error) {
		console.log(error);

	}
}

export function formatTimestamp(timestamp: string, onlyDate = false, onlyTime = false): string {
	if (!timestamp) {
		return ""
	}
	const date = dayjs.utc(timestamp);
	const formattedDate = date.format('DD-MMM-YYYY HH:mm');
	if (onlyDate) {
		return date.format('DD-MMM-YYYY');
	}
	if (onlyTime) {
		return date.format('HH:mm:ss');
	}
	return formattedDate;
}

export const synastryRulesNameArray = ref([
	{ rules: "as_000_as", userAPlanet: 'as', userBPlanet: 'as', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_ju", userAPlanet: 'as', userBPlanet: 'ju', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_ma", userAPlanet: 'as', userBPlanet: 'ma', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_me", userAPlanet: 'as', userBPlanet: 'me', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_mo", userAPlanet: 'as', userBPlanet: 'mo', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_ne", userAPlanet: 'as', userBPlanet: 'ne', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_pl", userAPlanet: 'as', userBPlanet: 'pl', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_sa", userAPlanet: 'as', userBPlanet: 'sa', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_su", userAPlanet: 'as', userBPlanet: 'su', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_ur", userAPlanet: 'as', userBPlanet: 'ur', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_000_ve", userAPlanet: 'as', userBPlanet: 've', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_030_as", userAPlanet: 'as', userBPlanet: 'as', deg: 30, orbsName: "semi-sextile", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_060_as", userAPlanet: 'as', userBPlanet: 'as', deg: 60, orbsName: "sextile", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_as", userAPlanet: 'as', userBPlanet: 'as', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_ju", userAPlanet: 'as', userBPlanet: 'ju', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_ma", userAPlanet: 'as', userBPlanet: 'ma', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_me", userAPlanet: 'as', userBPlanet: 'me', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_mo", userAPlanet: 'as', userBPlanet: 'mo', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_ne", userAPlanet: 'as', userBPlanet: 'ne', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_pl", userAPlanet: 'as', userBPlanet: 'pl', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_sa", userAPlanet: 'as', userBPlanet: 'sa', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_su", userAPlanet: 'as', userBPlanet: 'su', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_ur", userAPlanet: 'as', userBPlanet: 'ur', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_090_ve", userAPlanet: 'as', userBPlanet: 've', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_as", userAPlanet: 'as', userBPlanet: 'as', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_ju", userAPlanet: 'as', userBPlanet: 'ju', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_ma", userAPlanet: 'as', userBPlanet: 'ma', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_me", userAPlanet: 'as', userBPlanet: 'me', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_mo", userAPlanet: 'as', userBPlanet: 'mo', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_ne", userAPlanet: 'as', userBPlanet: 'ne', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_pl", userAPlanet: 'as', userBPlanet: 'pl', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_sa", userAPlanet: 'as', userBPlanet: 'sa', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_su", userAPlanet: 'as', userBPlanet: 'su', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_ur", userAPlanet: 'as', userBPlanet: 'ur', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_120_ve", userAPlanet: 'as', userBPlanet: 've', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_150_as", userAPlanet: 'as', userBPlanet: 'as', deg: 150, orbsName: "quincunx", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_as", userAPlanet: 'as', userBPlanet: 'as', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_ju", userAPlanet: 'as', userBPlanet: 'ju', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_ma", userAPlanet: 'as', userBPlanet: 'ma', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_me", userAPlanet: 'as', userBPlanet: 'me', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_mo", userAPlanet: 'as', userBPlanet: 'mo', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_ne", userAPlanet: 'as', userBPlanet: 'ne', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_pl", userAPlanet: 'as', userBPlanet: 'pl', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_sa", userAPlanet: 'as', userBPlanet: 'sa', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_su", userAPlanet: 'as', userBPlanet: 'su', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_ur", userAPlanet: 'as', userBPlanet: 'ur', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "as_180_ve", userAPlanet: 'as', userBPlanet: 've', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_000_ju", userAPlanet: 'ma', userBPlanet: 'ju', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_000_ma", userAPlanet: 'ma', userBPlanet: 'ma', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_000_ne", userAPlanet: 'ma', userBPlanet: 'ne', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_000_pl", userAPlanet: 'ma', userBPlanet: 'pl', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_000_sa", userAPlanet: 'ma', userBPlanet: 'sa', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_000_ur", userAPlanet: 'ma', userBPlanet: 'ur', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_090_ju", userAPlanet: 'ma', userBPlanet: 'ju', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_090_ma", userAPlanet: 'ma', userBPlanet: 'ma', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_090_ne", userAPlanet: 'ma', userBPlanet: 'ne', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_090_pl", userAPlanet: 'ma', userBPlanet: 'pl', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_090_sa", userAPlanet: 'ma', userBPlanet: 'sa', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_090_ur", userAPlanet: 'ma', userBPlanet: 'ur', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_120_ju", userAPlanet: 'ma', userBPlanet: 'ju', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_120_ma", userAPlanet: 'ma', userBPlanet: 'ma', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_120_ne", userAPlanet: 'ma', userBPlanet: 'ne', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_120_pl", userAPlanet: 'ma', userBPlanet: 'pl', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_120_sa", userAPlanet: 'ma', userBPlanet: 'sa', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_120_ur", userAPlanet: 'ma', userBPlanet: 'ur', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_180_ju", userAPlanet: 'ma', userBPlanet: 'ju', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_180_ma", userAPlanet: 'ma', userBPlanet: 'ma', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_180_ne", userAPlanet: 'ma', userBPlanet: 'ne', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_180_pl", userAPlanet: 'ma', userBPlanet: 'pl', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_180_sa", userAPlanet: 'ma', userBPlanet: 'sa', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ma_180_ur", userAPlanet: 'ma', userBPlanet: 'ur', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_000_ju", userAPlanet: 'me', userBPlanet: 'ju', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_000_ma", userAPlanet: 'me', userBPlanet: 'ma', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_000_me", userAPlanet: 'me', userBPlanet: 'me', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_000_ne", userAPlanet: 'me', userBPlanet: 'ne', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_000_pl", userAPlanet: 'me', userBPlanet: 'pl', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_000_sa", userAPlanet: 'me', userBPlanet: 'sa', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_000_ur", userAPlanet: 'me', userBPlanet: 'ur', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_000_ve", userAPlanet: 'me', userBPlanet: 've', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_090_ju", userAPlanet: 'me', userBPlanet: 'ju', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_090_ma", userAPlanet: 'me', userBPlanet: 'ma', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_090_me", userAPlanet: 'me', userBPlanet: 'me', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_090_ne", userAPlanet: 'me', userBPlanet: 'ne', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_090_pl", userAPlanet: 'me', userBPlanet: 'pl', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_090_sa", userAPlanet: 'me', userBPlanet: 'sa', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_090_ur", userAPlanet: 'me', userBPlanet: 'ur', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_090_ve", userAPlanet: 'me', userBPlanet: 've', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_120_ju", userAPlanet: 'me', userBPlanet: 'ju', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_120_ma", userAPlanet: 'me', userBPlanet: 'ma', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_120_me", userAPlanet: 'me', userBPlanet: 'me', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_120_ne", userAPlanet: 'me', userBPlanet: 'ne', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_120_pl", userAPlanet: 'me', userBPlanet: 'pl', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_120_sa", userAPlanet: 'me', userBPlanet: 'sa', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_120_ur", userAPlanet: 'me', userBPlanet: 'ur', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_120_ve", userAPlanet: 'me', userBPlanet: 've', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_180_ju", userAPlanet: 'me', userBPlanet: 'ju', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_180_ma", userAPlanet: 'me', userBPlanet: 'ma', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_180_me", userAPlanet: 'me', userBPlanet: 'me', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_180_ne", userAPlanet: 'me', userBPlanet: 'ne', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_180_pl", userAPlanet: 'me', userBPlanet: 'pl', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_180_sa", userAPlanet: 'me', userBPlanet: 'sa', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_180_ur", userAPlanet: 'me', userBPlanet: 'ur', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "me_180_ve", userAPlanet: 'me', userBPlanet: 've', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_ju", userAPlanet: 'mo', userBPlanet: 'ju', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_ma", userAPlanet: 'mo', userBPlanet: 'ma', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_me", userAPlanet: 'mo', userBPlanet: 'me', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_mo", userAPlanet: 'mo', userBPlanet: 'mo', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_ne", userAPlanet: 'mo', userBPlanet: 'ne', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_pl", userAPlanet: 'mo', userBPlanet: 'pl', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_sa", userAPlanet: 'mo', userBPlanet: 'sa', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_ur", userAPlanet: 'mo', userBPlanet: 'ur', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_000_ve", userAPlanet: 'mo', userBPlanet: 've', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_ju", userAPlanet: 'mo', userBPlanet: 'ju', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_ma", userAPlanet: 'mo', userBPlanet: 'ma', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_me", userAPlanet: 'mo', userBPlanet: 'me', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_mo", userAPlanet: 'mo', userBPlanet: 'mo', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_ne", userAPlanet: 'mo', userBPlanet: 'ne', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_pl", userAPlanet: 'mo', userBPlanet: 'pl', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_sa", userAPlanet: 'mo', userBPlanet: 'sa', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_ur", userAPlanet: 'mo', userBPlanet: 'ur', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_090_ve", userAPlanet: 'mo', userBPlanet: 've', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_ju", userAPlanet: 'mo', userBPlanet: 'ju', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_ma", userAPlanet: 'mo', userBPlanet: 'ms', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_me", userAPlanet: 'mo', userBPlanet: 'me', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_mo", userAPlanet: 'mo', userBPlanet: 'mo', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_ne", userAPlanet: 'mo', userBPlanet: 'ne', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_pl", userAPlanet: 'mo', userBPlanet: 'pl', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_sa", userAPlanet: 'mo', userBPlanet: 'sa', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_ur", userAPlanet: 'mo', userBPlanet: 'ur', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_120_ve", userAPlanet: 'mo', userBPlanet: 've', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_ju", userAPlanet: 'mo', userBPlanet: 'ju', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_ma", userAPlanet: 'mo', userBPlanet: 'ma', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_me", userAPlanet: 'mo', userBPlanet: 'me', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_mo", userAPlanet: 'mo', userBPlanet: 'mo', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_ne", userAPlanet: 'mo', userBPlanet: 'ne', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_pl", userAPlanet: 'mo', userBPlanet: 'pl', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_sa", userAPlanet: 'mo', userBPlanet: 'sa', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_ur", userAPlanet: 'mo', userBPlanet: 'ur', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "mo_180_ve", userAPlanet: 'mo', userBPlanet: 've', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_ju", userAPlanet: 'su', userBPlanet: 'ju', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_ma", userAPlanet: 'su', userBPlanet: 'ma', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_me", userAPlanet: 'su', userBPlanet: 'me', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_mo", userAPlanet: 'su', userBPlanet: 'mo', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_ne", userAPlanet: 'su', userBPlanet: 'ne', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_pl", userAPlanet: 'su', userBPlanet: 'pl', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_sa", userAPlanet: 'su', userBPlanet: 'sa', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_su", userAPlanet: 'su', userBPlanet: 'su', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_ur", userAPlanet: 'su', userBPlanet: 'ur', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_000_ve", userAPlanet: 'su', userBPlanet: 've', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_ju", userAPlanet: 'su', userBPlanet: 'ju', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_ma", userAPlanet: 'su', userBPlanet: 'ma', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_me", userAPlanet: 'su', userBPlanet: 'me', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_mo", userAPlanet: 'su', userBPlanet: 'mo', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_ne", userAPlanet: 'su', userBPlanet: 'ne', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_pl", userAPlanet: 'su', userBPlanet: 'pl', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_sa", userAPlanet: 'su', userBPlanet: 'sa', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_su", userAPlanet: 'su', userBPlanet: 'su', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_ur", userAPlanet: 'su', userBPlanet: 'ur', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_090_ve", userAPlanet: 'su', userBPlanet: 've', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_ju", userAPlanet: 'su', userBPlanet: 'ju', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_ma", userAPlanet: 'su', userBPlanet: 'ma', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_me", userAPlanet: 'su', userBPlanet: 'me', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_mo", userAPlanet: 'su', userBPlanet: 'mo', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_ne", userAPlanet: 'su', userBPlanet: 'ne', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_pl", userAPlanet: 'su', userBPlanet: 'pl', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_sa", userAPlanet: 'su', userBPlanet: 'sa', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_su", userAPlanet: 'su', userBPlanet: 'su', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_ur", userAPlanet: 'su', userBPlanet: 'ur', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_120_ve", userAPlanet: 'su', userBPlanet: 've', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_ju", userAPlanet: 'su', userBPlanet: 'ju', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_ma", userAPlanet: 'su', userBPlanet: 'ma', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_me", userAPlanet: 'su', userBPlanet: 'me', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_mo", userAPlanet: 'su', userBPlanet: 'mo', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_ne", userAPlanet: 'su', userBPlanet: 'ne', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_pl", userAPlanet: 'su', userBPlanet: 'pl', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_sa", userAPlanet: 'su', userBPlanet: 'sa', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_su", userAPlanet: 'su', userBPlanet: 'su', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_ur", userAPlanet: 'su', userBPlanet: 'ur', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "su_180_ve", userAPlanet: 'su', userBPlanet: 've', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_000_ju", userAPlanet: 've', userBPlanet: 'ju', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_000_ma", userAPlanet: 've', userBPlanet: 'ma', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_000_ne", userAPlanet: 've', userBPlanet: 'ne', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_000_pl", userAPlanet: 've', userBPlanet: 'pl', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_000_sa", userAPlanet: 've', userBPlanet: 'sa', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_000_ur", userAPlanet: 've', userBPlanet: 'ur', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_000_ve", userAPlanet: 've', userBPlanet: 've', deg: 0, orbsName: "conjunction", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_090_ju", userAPlanet: 've', userBPlanet: 'ju', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_090_ma", userAPlanet: 've', userBPlanet: 'ma', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_090_ne", userAPlanet: 've', userBPlanet: 'ne', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_090_pl", userAPlanet: 've', userBPlanet: 'pl', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_090_sa", userAPlanet: 've', userBPlanet: 'sa', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_090_ur", userAPlanet: 've', userBPlanet: 'ur', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_090_ve", userAPlanet: 've', userBPlanet: 've', deg: 90, orbsName: "square", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_120_ju", userAPlanet: 've', userBPlanet: 'ju', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_120_ma", userAPlanet: 've', userBPlanet: 'ma', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_120_ne", userAPlanet: 've', userBPlanet: 'ne', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_120_pl", userAPlanet: 've', userBPlanet: 'pl', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_120_sa", userAPlanet: 've', userBPlanet: 'sa', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_120_ur", userAPlanet: 've', userBPlanet: 'ur', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_120_ve", userAPlanet: 've', userBPlanet: 've', deg: 120, orbsName: "trine", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_180_ju", userAPlanet: 've', userBPlanet: 'ju', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_180_ma", userAPlanet: 've', userBPlanet: 'ma', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_180_ne", userAPlanet: 've', userBPlanet: 'ne', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_180_pl", userAPlanet: 've', userBPlanet: 'pl', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_180_sa", userAPlanet: 've', userBPlanet: 'sa', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_180_ur", userAPlanet: 've', userBPlanet: 'ur', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
	{ rules: "ve_180_ve", userAPlanet: 've', userBPlanet: 've', deg: 180, orbsName: "opposition", orbsValue: 0, minOrbsValue: 0, maxOrbsValue: 0, isVisible: false, communication: 0, connection: 0, energy: 0, stability: 0 },
])