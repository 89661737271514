<template>
	<div :style="{ padding: '20px 20px 0px 20px' }">
		<a-tabs default-active-key="1" :bordered="false" @change="activeTabChange" class="custom-tabs">
			<a-tab-pane key="1">
				<template #tab>
					<div class="tab-with-icon">
						<TeamOutlined />
						<span>Members</span>
					</div>
				</template>
			</a-tab-pane>
			<a-tab-pane key="2">
				<template #tab>
					<div class="tab-with-icon">
						<UserDeleteOutlined />
						<span>Blocked</span>
					</div>
				</template>
			</a-tab-pane>
			<template #rightExtra>
				<div class="extra-content">
					<a-button @click="() => (drawerOpen = true)" type="primary">Additional fields</a-button>
					<AdditionalFieldsDrawer :closeDrawer="closeDrawer" :drawerOpen="drawerOpen" :columns="toJson.columns ? toJson.columns : columns" :resetColumn="resetColumn" />
					<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
					<!-- <a-button type="primary">
					<template #icon><DownloadOutlined /></template>
				</a-button> -->
				</div>
			</template>
		</a-tabs>

		<!-- <div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }"><TeamOutlined /> Members</a-breadcrumb-item>
			</a-breadcrumb>
			<a-button @click="() => (drawerOpen = true)" type="primary">Additional fields</a-button>
			<AdditionalFieldsDrawer :closeDrawer="closeDrawer" :drawerOpen="drawerOpen" :columns="toJson.columns ? toJson.columns : columns" :resetColumn="resetColumn" />
			<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
			<a-button type="primary">
				<template #icon><DownloadOutlined /></template>
			</a-button>
		</div> -->

		<a-table
			size="middle"
			bordered
			@change="handleChange"
			:columns="toJson.columns?toJson.columns.filter((column:any) => column.visible):columns.filter((column:any) => column.visible)"
			:data-source="data"
			:pagination="{
			  current: pagination.current,
				pageSize: pagination.pageSize,
				total: pagination.total,
				position: ['topRight'],
				size: 'small',
				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
				showSizeChanger: true, 
			}"
			:loading="loading"
			:scroll="{ x: 1, y: height - 245 }"
			class="tableBody"
		>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px" v-if="column.customCalendar">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {startDate(e), (selectedKeys[0] = e)}" :disabledDate="disabledDate" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {endDate(e), (selectedKeys[1] = e)}" :disabledDate="disabledDate" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div style="padding: 8px" v-else-if="column.modifiedAtCalendar">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {modifiedAtStartDate(e), (selectedKeys[0] = e)}" :disabledDate="disabledDate" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {modifiedAtEndDate(e), (selectedKeys[1] = e)}" :disabledDate="disabledDate" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div style="padding: 8px" v-else-if="column.loginCalendar">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {loginStartDate(e), (selectedKeys[0] = e)}" :disabledDate="disabledDate" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {loginEndDate(e), (selectedKeys[1] = e)}" :disabledDate="disabledDate" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div style="padding: 8px" v-else-if="column.dobCalendar">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {dobStartDate(e), (selectedKeys[0] = e)}" :disabledDate="disabledDate" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {dobEndDate(e), (selectedKeys[1] = e)}" :disabledDate="disabledDate" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div :style="{ padding: '8px' }" v-else-if="column.customAge">
					<h4>Min Age :</h4>
					<a-input class="filterInput" :value="selectedKeys[0]" placeholder="min age" @change="(e:any) => {minAge(e), (selectedKeys[0] = e.target.value);}" />
					<h4>Max Age :</h4>
					<a-input placeholder="max age" :value="selectedKeys[1]" class="filterInput" @change="(e:any) => {maxAge(e), (selectedKeys[1] = e.target.value);	}" />
					<div :style="{ display: 'flex', justifyContent: 'center' }">
						<a-button :style="{ marginRight: '15px', width: '100%' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
						<a-button :style="{ width: '100%' }" type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Submit </a-button>
					</div>
				</div>
				<div style="padding: 8px" v-else>
					<a-input
						:placeholder="`Search ${column.title}`"
						:value="selectedKeys[0]"
						class="filterInput"
						@change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])
			"
						@pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
					/>
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
			</template>
			<template #bodyCell="{ text, column, record }">
				<template v-if="column && column.dataIndex === 'nickName'">
					<a @click="setLocalStorageData">
						<router-link :to="'/members/' + record._id" :data="data">{{ truncateText(text, 18) }}</router-link>
					</a>
				</template>
				<template v-if="column.dataIndex !== 'nickName' && text">
					<div>
						{{ truncateText(text, 35) }}
					</div>
				</template>
			</template>
		</a-table>
	</div>
</template>
<script lang="ts" setup>
	import { DownloadOutlined, TeamOutlined, UserDeleteOutlined } from "@ant-design/icons-vue";
	import { Ref, ref } from "vue";
	import type { TableProps } from "ant-design-vue";
	import { SearchOutlined } from "@ant-design/icons-vue";
	import { ColumnsData } from "./../interface/Members.interface";
	import { removeNullFromObj, truncateText, useScreenSize } from "../utils/index";
	import { getUserList, mapData, filteredUser } from "../services/api/user";
	import AdditionalFieldsDrawer from "@/components/AdditionalFieldsDrawer.vue";
	import { calculateDate } from "../utils/index";
	import dayjs from "dayjs";
	import router from "@/router";

	interface User {
		gender?: string;
		nickName?: string;
	}
	const loading = ref();
	const data = ref<User[]>([]);
	const selectedUser = ref<any>({});
	const toJson: any = ref({ userList: {}, filtered: [] });
	const filtered: any = ref([]);
	const activeTab = ref("1");
	const userListFilter: any = ref({
		pageNo: 1,
		sortBy: null,
		orderBy: null,
		pageLimit: 20,
		search: null,
		searchBy: null,
		type: "active",
	});

	const pagination = ref({ pageSize: 20, total: 0, current: 0 });
	const columnsData: ColumnsData[] = [
		{
			title: "Name",
			dataIndex: "nickName",
			key: "nickName",
			visible: true,
			fixed: true,
			category: "userProfile",
			sorter: true,
			customFilterDropdown: true,
			width: 150,
		},
		{
			title: "Email",
			dataIndex: "identifier",
			key: "identifier",
			visible: true,
			category: "userProfile",
			width: 300,
			sorter: true,
			customFilterDropdown: true,
		},
		{
			title: "Age",
			dataIndex: "age",
			key: "age",
			visible: true,
			category: "userProfile",
			customAge: true,
			filterMultiple: true,
			customFilterDropdown: true,
			width: 100,
			sorter: true,
			align: "center",
		},
		{
			title: "Gender",
			dataIndex: "gender",
			key: "gender",
			visible: true,
			category: "userProfile",
			filters: [
				{ text: "Male", value: "m" },
				{ text: "Female", value: "f" },
			],
			filterMultiple: false,
			sorter: true,
			width: 100,
			align: "center",
		},
		{
			title: "Current Location",
			dataIndex: "location",
			key: "location",
			category: "userProfile",
			visible: true,
			align: "center",
			width: 300,
			customFilterDropdown: true,
		},
		{
			title: "Bio",
			dataIndex: "bio",
			key: "bio",
			visible: true,
			category: "userProfile",
			align: "center",
			sorter: true,
			width: 300,
			customFilterDropdown: true,
		},
		{
			title: "Residence",
			dataIndex: "residence",
			key: "residence",
			visible: true,
			category: "userProfile",
			width: 200,
			align: "center",
			customFilterDropdown: true,
		},
		{
			title: "Profession",
			dataIndex: "profession",
			key: "profession",
			visible: true,
			category: "userProfile",
			width: 200,
			align: "center",
			customFilterDropdown: true,
		},
		{
			title: "DOB",
			dataIndex: "dob",
			key: "dob",
			visible: true,
			category: "userProfile",
			sorter: true,
			align: "center",
			dobCalendar: true,
			width: 150,
			customFilterDropdown: true,
			filterMultiple: true,
		},
		{
			title: "TOB",
			dataIndex: "tob",
			key: "tob",
			visible: true,
			width: 150,
			category: "userProfile",
			filterMultiple: true,
			align: "center",
		},
		{
			title: "POB",
			dataIndex: "pob",
			key: "pob",
			category: "userProfile",
			visible: true,
			sorter: false,
			width: 300,
			customFilterDropdown: true,
			filterMultiple: true,
			align: "center",
		},
		{
			title: "User Status",
			dataIndex: "userStatus",
			key: "userStatus",
			visible: true,
			category: "userProfile",
			align: "center",
			filters: [
				// { text: "Incomplete", value: "inCompleted" },
				{ text: "Dormate", value: "dormate" },
				{ text: "Active", value: "active" },
				// { text: "Admin Block", value: "adminBlock" },
			],
			filterMultiple: false,
			width: 180,
		},
		{
			title: "Last Login",
			dataIndex: "login",
			key: "login",
			visible: true,
			category: "accountStatus",
			sorter: true,
			loginCalendar: true,
			customFilterDropdown: true,
			width: 150,
			filterMultiple: true,
			align: "center",
		},
		{
			title: "Last Edited",
			dataIndex: "modifiedAt",
			key: "modifiedAt",
			category: "accountStatus",
			visible: true,
			sorter: true,
			align: "center",
			customFilterDropdown: true,
			width: 150,
			filterMultiple: true,
			modifiedAtCalendar: true,
		},
		{
			title: "Joined Date",
			dataIndex: "createdAt",
			key: "createdAt",
			visible: true,
			category: "accountStatus",
			sorter: true,
			align: "center",
			customFilterDropdown: true,
			width: 150,
			filterMultiple: true,
			customCalendar: true,
		},
		{
			title: "Times Reported",
			dataIndex: "reported",
			key: "reported",
			visible: false,
			category: "accountStatus",
			width: 150,
			sorter: false,
			filterMultiple: false,
			align: "center",
		},
		{
			title: "Times Blocked",
			dataIndex: "blocked",
			key: "blocked",
			category: "accountStatus",
			visible: false,
			width: 150,
			filterMultiple: false,
			sorter: false,
			align: "center",
		},
		{
			title: "Wants to meet",
			dataIndex: "wantsToMeet",
			key: "wantsToMeet",
			visible: false,
			category: "userSetting",
			filter: true,
			filters: [
				{ text: "Male", value: "m" },
				{ text: "Female", value: "f" },
			],
			width: 150,
			filterMultiple: false,
			align: "center",
		},
		{
			title: "Age range",
			dataIndex: "ageRange",
			key: "ageRange",
			visible: false,
			width: 150,
			category: "userSetting",
			align: "center",
		},
		{
			title: "Distance Range",
			dataIndex: "distanceRange",
			key: "distanceRange",
			category: "userSetting",
			width: 150,
			visible: false,
			align: "center",
		},
		{
			title: "Purpose",
			dataIndex: "purpose",
			key: "purpose",
			visible: false,
			width: 150,
			category: "userSetting",
			filters: [
				{ text: "Casual Dating", value: "casual_dating" },
				{ text: "Long Term Dating", value: "long_term_dating" },
				{ text: "Networking", value: "networking" },
				{ text: "Friendship", value: "friendship" },
				{ text: "Other", value: "other" },
			],
			filterMultiple: true,
			align: "center",
		},
		{
			title: "Residence",
			dataIndex: "residence",
			key: "residence",
			align: "center",
			visible: false,
			category: "userSetting",
			customFilterDropdown: true,
			width: 200,
			sorter: true,
		},
		{
			title: "Occupation",
			dataIndex: "profession",
			width: 150,
			key: "profession",
			visible: false,
			category: "userSetting",
			customFilterDropdown: true,
			align: "center",
		},
		{
			title: "Relationship Status",
			dataIndex: "relationshipStatus",
			key: "relationshipStatus",
			align: "center",
			visible: false,
			width: 200,
			category: "userSetting",
			filters: [
				{ text: "Married", value: "married" },
				{ text: "Engaged", value: "engaged" },
				{ text: "Separated", value: "separated" },
				{ text: "Single", value: "single" },
				{ text: "Divorced", value: "divorced" },
			],
			filterMultiple: true,
		},
		{
			title: "BirthChart Style",
			dataIndex: "birthChartStyle",
			key: "birthChartStyle",
			align: "center",
			visible: false,
			width: 150,
			category: "userSetting",
			filters: [
				{ text: "North India", value: "ni" },
				{ text: "South India", value: "si" },
				{ text: "East India", value: "ei" },
				{ text: "Western", value: "w" },
			],
			filterMultiple: true,
		},
		{
			title: "Tropical/Sidereal",
			dataIndex: "tropical_sidereal",
			key: "tropical_sidereal",
			align: "center",
			visible: false,
			category: "userSetting",
			width: 150,
			filters: [
				{ text: "Tropical", value: "t" },
				{ text: "Sidereal", value: "s" },
			],
			filterMultiple: true,
		},
		{
			title: "Notification-Likes",
			dataIndex: "notificationLikes",
			category: "notificationSettings",
			key: "notificationLikes",
			align: "center",
			width: 150,
			visible: false,
			filters: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
			filterMultiple: true,
		},
		{
			title: "Notification-SuperLikes",
			dataIndex: "notificationSuperLikes",
			category: "notificationSettings",
			key: "notificationSuperLikes",
			align: "center",
			width: 200,
			visible: false,
			filters: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
			filterMultiple: true,
		},
		{
			title: "Notification-Message",
			dataIndex: "notificationMessage",
			category: "notificationSettings",
			key: "notificationMessage",
			align: "center",
			width: 200,
			visible: false,
			filters: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
			filterMultiple: true,
		},
		{
			title: "Notifications-Matches",
			dataIndex: "notificationMatches",
			category: "notificationSettings",
			key: "notificationMatches",
			align: "center",
			width: 200,
			visible: true,
			filters: [
				{ text: "Yes", value: true },
				{ text: "No", value: false },
			],
			filterMultiple: true,
		},
		{
			title: "Passes/w",
			dataIndex: "userSwipes",
			category: "swipeActivities",
			key: "userSwipes",
			visible: false,
			width: 150,
			sorter: false,
			align: "center",
		},
		{
			title: "Likes/w",
			dataIndex: "userLikes",
			category: "swipeActivities",
			key: "userLikes",
			visible: false,
			width: 150,
			sorter: false,
			align: "center",
		},
		{
			title: "Super Likes/w",
			dataIndex: "userSuperLikes",
			category: "swipeActivities",
			key: "userSuperLikes",
			visible: false,
			width: 150,
			sorter: false,
			align: "center",
		},
		{
			title: "Matches/w",
			dataIndex: "matchesMade",
			category: "swipeActivities",
			key: "matchesMade",
			width: 150,
			visible: false,
			sorter: false,
			align: "center",
		},
		{
			title: "MatchMeUp/w",
			dataIndex: "matchMeupTimeCount",
			key: "matchMeupTimeCount",
			width: 150,
			visible: false,
			sorter: false,
			align: "center",
		},
		{
			title: "Lucky Times Visit/w",
			dataIndex: "luckyTimeCount",
			key: "luckyTimeCount",
			width: 200,
			visible: false,
			sorter: false,
			align: "center",
		},
		{
			title: "Astro Comp visit/w",
			dataIndex: "astroCompVisitCount",
			key: "astroCompVisitCount",
			width: 200,
			visible: false,
			sorter: false,
			align: "center",
		},
		{
			title: "Jungian Type",
			dataIndex: "jungianType",
			key: "jungianType",
			width: 150,
			visible: false,
			filters: [
				{ text: "ISTJ", value: "ISTJ" },
				{ text: "ISFJ", value: "ISFJ" },
				{ text: "INFJ", value: "INFJ" },
				{ text: "INTJ", value: "INTJ" },
				{ text: "ISTP", value: "ISTP" },
				{ text: "ISFP", value: "ISFP" },
				{ text: "INFP", value: "INFP" },
				{ text: "INTP", value: "INTP" },
				{ text: "ESTJ", value: "ESTJ" },
				{ text: "ESFJ", value: "ESFJ" },
				{ text: "ENFJ", value: "ENFJ" },
				{ text: "ENTJ", value: "ENTJ" },
				{ text: "ESTP", value: "ESTP" },
				{ text: "ESFP", value: "ESFP" },
				{ text: "ENFP", value: "ENFP" },
				{ text: "ENTP", value: "ENTP" },
			],
			align: "center",
			filterMultiple: true,
		},
		{
			title: "Quiz Completed(Y/N)",
			dataIndex: "answeredQuiz",
			key: "answeredQuiz",
			width: 200,
			visible: false,
			align: "center",
		},
		{
			title: "Jungian Comp visit/w",
			dataIndex: "jugnianCompVisitCount",
			key: "jugnianCompVisitCount",
			width: 200,
			visible: false,
			sorter: false,
			align: "center",
		},
		{
			title: "Device Model",
			dataIndex: "device",
			key: "device",
			width: 150,
			visible: false,
			align: "center",
			category: "device",
		},
		{
			title: "App Version",
			dataIndex: "appVersion",
			key: "appVersion",
			width: 200,
			visible: false,
			align: "center",
			category: "device",
		},
		{
			title: "Build Number",
			dataIndex: "build",
			key: "build",
			width: 200,
			visible: false,
			align: "center",
			category: "device",
		},
	];
	const { height } = useScreenSize();
	const abc = [...columnsData];
	const drawerOpen = ref(false);
	const modalOpen = ref(false);
	const columns: Ref<ColumnsData[]> = ref<ColumnsData[]>([...columnsData]);
	const searchBy = ref("");
	const firstUser = ref(false);
	const lastUser = ref(false);

	const handleChange: TableProps["onChange"] = async (pagination, filters, sorter: any) => {
		if (filters.gender) {
			userListFilter.value.gender = filters.gender.join(",");
		}
		if (filters.wantsToMeet) {
			userListFilter.value.wantsToMeet = filters.wantsToMeet.join(",");
		}
		if (filters.accountStatus) {
			userListFilter.value.accountStatus = filters.accountStatus.join(",");
		}
		if (filters.jungianType) {
			userListFilter.value.jungianType = filters.jungianType.join(",");
		}
		if (filters.purpose) {
			userListFilter.value.purpose = filters.purpose.join(",");
		}
		if (filters.notificationLikes) {
			userListFilter.value.notificationLikes = filters.notificationLikes.join(",");
		}
		if (filters.notificationSuperLikes) {
			userListFilter.value.notificationSuperLikes = filters.notificationSuperLikes.join(",");
		}
		if (filters.notificationMessage) {
			userListFilter.value.notificationMessage = filters.notificationMessage.join(",");
		}
		if (filters.notificationMatches) {
			userListFilter.value.notificationMatches = filters.notificationMatches.join(",");
		}
		if (filters.relationshipStatus) {
			userListFilter.value.relationshipStatus = filters.relationshipStatus.join(",");
		}
		if (filters.tropical_sidereal) {
			userListFilter.value.tropical_sidereal = filters.tropical_sidereal.join(",");
		}
		if (filters.birthChartStyle) {
			userListFilter.value.birthChartStyle = filters.birthChartStyle.join(",");
		}
		if (sorter.field == "age") {
			sorter.field = "dob";
		}
		if (sorter.field == "accountStatus") {
			sorter.field = "active";
		}
		if (filters.userStatus) {
			userListFilter.value.user = filters.userStatus.join(",");
		}
		userListFilter.value.pageNo = pagination.current;
		userListFilter.value.pageLimit = pagination.pageSize;
		userListFilter.value.orderBy = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
		userListFilter.value.sortBy = sorter.order === undefined ? null : sorter.field;
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		fetchData();
	};
	const resetColumn = async (newColumnsData: ColumnsData[]) => {
		columns.value = newColumnsData;
	};
	const handleSearch = async (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
		if (dataIndex === "nickName" || dataIndex === "identifier" || dataIndex === "place" || dataIndex === "pob" || dataIndex === "residence" || dataIndex === "profession" || dataIndex === "location" || dataIndex === "bio") {
			userListFilter.value.searchBy = dataIndex;
			userListFilter.value.search = selectedKeys[0] && selectedKeys[0].trim();
		} else {
			filtered.value.push(dataIndex);
		}
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		setTimeout(() => {
			fetchData();
		}, 1000);
	};

	const startDate = (e: any) => {
		userListFilter.value.createdStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};

	const endDate = (e: any) => {
		userListFilter.value.createdEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};

	const modifiedAtStartDate = (e: any) => {
		userListFilter.value.lastEditedStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};
	const modifiedAtEndDate = (e: any) => {
		userListFilter.value.lastEditedEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};

	const loginStartDate = (e: any) => {
		userListFilter.value.loginStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};
	const loginEndDate = (e: any) => {
		userListFilter.value.loginEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};

	const dobStartDate = (e: any) => {
		userListFilter.value.dobStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};
	const dobEndDate = (e: any) => {
		userListFilter.value.dobEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};
	const minAge = async (e: any) => {
		const age = await calculateDate(e.target.value);
		userListFilter.value.dobEndDate = age.startDate.toISOString();
	};
	const maxAge = async (e: any) => {
		const age = await calculateDate(e.target.value);
		userListFilter.value.dobStartDate = age.endDate.toISOString();
	};

	const disabledDate = (date: any) => {
		return dayjs(date).isAfter(dayjs().add(0, "days"), "day");
	};
	const clearFilters = async () => {
		loading.value = true;
		userListFilter.value = {
			pageNo: 1,
			pageLimit: 20,
			type: "active",
		};
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		await localStorage.removeItem("columns");
		router.go(0);
	};

	const closeDrawer = () => {
		drawerOpen.value = false;
	};

	const handleReset = async (clearFilters: (arg0: { confirm: boolean }) => void, confirm: () => void, dataIndex: string) => {
		confirm();
		clearFilters({ confirm: true });
		if (dataIndex == "dob" || dataIndex == "age") {
			userListFilter.value.dobEndDate = null;
			userListFilter.value.dobStartDate = null;
		}
		if (dataIndex == "createdAt") {
			userListFilter.value.createdEndDate = null;
			userListFilter.value.createdStartDate = null;
		}
		if (dataIndex == "login") {
			userListFilter.value.loginEndDate = null;
			userListFilter.value.loginStartDate = null;
		}
		if (dataIndex == "modifiedAt") {
			userListFilter.value.lastEditedStartDate = null;
			userListFilter.value.lastEditedEndDate = null;
		}
		if (dataIndex == "jungianType") {
			userListFilter.value.jungianType = null;
		}
		userListFilter.value.search = "";
		userListFilter.value.searchBy = "";
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		setTimeout(() => {
			fetchData();
		}, 1000);
	};

	const viewDetails = (record: any) => {
		for (let i = 0; i < data.value.length; i++) {
			const user = data.value[i];
			// if (user._id == record._id) {
			// 	selectedUser.value = user;
			// }
		}
		modalOpen.value = true;
	};

	const setLocalStorageData = async () => {};

	const storeIds = async (apiRes: any) => {
		const ids = apiRes.items.map((a: any) => a._id);
		localStorage.setItem("ids", JSON.stringify(ids));
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const userListFromLocalStorage: any = localStorage.getItem("userListFilter");
			if (userListFromLocalStorage) {
				toJson.value = JSON.parse(userListFromLocalStorage);
			}
			const localStorageColumn: any = localStorage.getItem("columns");
			if (localStorageColumn) {
				toJson.value.columns = JSON.parse(localStorageColumn);
			}
			await removeNullFromObj(toJson.value.userList);
			let filter = { ...userListFilter.value };
			if (toJson.value.userList.type == "active") {
				filter = { ...toJson.value.userList };
			}
			if (activeTab.value == "2") {
				filter.isBlocked = true;
			} else {
				filter.isBlocked = false;
			}
			const apiRes: any = await getUserList(filter);
			storeIds(apiRes);
			data.value = await mapData(apiRes?.items, toJson.value.userList.type == "active" ? { ...toJson.value.userList } : { ...userListFilter.value });
			pagination.value.pageSize = Number(apiRes?.pageLimit);
			pagination.value.total = Number(apiRes?.TotalFilteredUser);
			pagination.value.current = Number(apiRes?.pageNo);
			// await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));

			await filteredUser(toJson.value.columns ? toJson.value.columns : columns.value, toJson.value);

			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	fetchData();
	const activeTabChange = (key: string) => {
		activeTab.value = key;
		if (key === "2") {
			fetchData();
		} else if (key === "1") {
			fetchData();
		}
	};
</script>
<style scoped>
	.table-operations {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
	}

	.table-operations > button {
		margin-left: 8px;
	}

	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}

	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
	.ant-tabs-nav {
		margin: 0px !important;
	}
	.extra-content {
		display: flex;
		gap: 15px;
	}
</style>
